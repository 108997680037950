import EasyValidation from './validationHelper.js';

//Variables
const validate = new EasyValidation();
const txtName = document.getElementById('txtName');
const txtMobile = document.getElementById('txtMobile');
const txtEmail = document.getElementById('txtEmail');
const txtComment = document.getElementById('txtComment');
const txtCompany = document.getElementById('txtCompany');
//const chkPolicy = document.getElementById('chkPolicy');
const btnSubmit = document.getElementById('btnSubmit');
//const lblMsg = document.getElementById('lblMsg');
//const spinner = document.getElementById('spinner');
//const BASEURL = `https://localhost:7168/api/sendEmail`;
//const BASEURL = `http://192.168.7.13:1412/api/sendEmail`;
const BASEURL = `https://utility.trueformgames.com/api/sendEmail`;
let submitCount = 0;
window.addEventListener('load', () => {
  // lblMsg.innerHTML = '';
  submitCount = 0;
});
// validate Name
txtName.addEventListener('blur', () => {
  validate.name(txtName);
});
// validate Mobile
txtMobile.addEventListener('blur', () => {
  validate.number(txtMobile);
});
// validate email
txtEmail.addEventListener('blur', () => {
  validate.email(txtEmail);
});
// validate Company
txtCompany.addEventListener('blur', () => {
  validate.name(txtCompany);
});
// validate Message
txtComment.addEventListener('blur', () => {
  validate.message(txtComment);
});

//api Calling
const postWithFormData = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    body: data,
  });
  const resData = await response.json();
  return resData;
};

//Create Body
function createMessageBody() {
  let body = `<table>
    <tr>
        <th>Name: </th>
        <td>${txtName.value}</td>
    </tr>
    <tr>
        <th>Email: </th>
        <td>${txtEmail.value}</td>
    </tr>
    <tr>
        <th>Mobile No: </th>
        <td>${txtMobile.value}</td>
    </tr>
    <tr>
        <th>Company: </th>
        <td>${txtCompany.value}</td>
    </tr>
    <tr>
        <th>Message: </th>
        <td>${txtComment.value}</td>
    </tr>
</table>`;
  return body;
}

//Create Json
const jsonData = async () => {
  var json = {};
  // json.to = 'info@criteriontech.in';
  json.to = 'ramanchawla.ct@gmail.com';
  json.body = createMessageBody();
  json.subject = 'Get In Touch';
  json.key = 'Er@Development';
  return json;
};
// Json to form data converter
const jsonToFormConverter = async (json) => {
  var form_data = new FormData();
  for (var key in json) {
    form_data.append(key, json[key]);
  }
  return form_data;
};

// Reset Field
function restField() {
  txtName.value = '';
  txtMobile.value = '';
  txtEmail.value = '';
  txtCompany.value = '';
  txtComment.value = '';
  // chkPolicy.checked = false;
}
// Submit
btnSubmit.addEventListener('click', async (e) => {
  e.preventDefault();
  console.log('first');
  if (submitCount < 1) {
    if (
      validate.name(txtName) ||
      validate.email(txtEmail) ||
      validate.number(txtMobile) ||
      validate.name(txtCompany) ||
      validate.message(txtComment)
      //  ||validate.checkBox(chkPolicy)
    ) {
      e.stopPropagation();
    } else {
      // spinner.classList.add('spinnershow');
      submitCount++;
      var data = await jsonToFormConverter(await jsonData());
      let response = await postWithFormData(BASEURL, data);
      if (response.isCompleted == true) {
        // spinner.classList.remove('spinnershow');
        // lblMsg.classList.add('success-message');
        // lblMsg.innerHTML =
        // ('Your query has been received. Our support team will get in touch.');
        restField();
        setInterval(() => {
          //  lblMsg.innerHTML = '';
          // lblMsg.classList.remove('success-message');
          //  spinner.classList.remove('spinnershow');
        }, 5000);
      } else {
        // spinner.classList.remove('spinnershow');
        // lblMsg.classList.add('error-message');
        // lblMsg.innerHTML = 'Something went wrong!';
        restField();
        setInterval(() => {
          // lblMsg.innerHTML = '';
          //lblMsg.classList.remove('error-message');
          //  spinner.classList.remove('spinnershow');
        }, 5000);
      }
    }
  }
});
